<template>
    <!-- eslint-disable max-len -->
        <b-container>
            <b-row>
                <b-col sm="12">
                    <div class="w-100 mx-auto text-left">
                        <font-awesome-icon icon="square-full" class="ml-auto square-1"/>
                    </div>
                    <h3>Get in Touch</h3>
                </b-col>

                <b-col sm="8" class="mt-2  px-2 px-md-1 mx-auto">
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <div class="col-12 mx-auto">
                            <span class="px-2 py-1 block-f-1">
                                <span class="block-f-2 px-2">artis</span>
                                <span class="block-f-3">@</span>
                                <span class="block-f-4">rozenlauks.lv</span>
                            </span>
                            <a class="ml-2 text-dark-gray" href="mailto:artis@rozenlauks.lv?subject=Portfolio%20-%20Direct%20message">
                                <font-awesome-icon icon="arrow-right" />
                            </a>
                        </div>
                    </div>
                    <div class="mt-4 row text-center">
                        <div class="col-4 mx-auto">Social</div>
                    </div>
                    <div class="row p-3 item mb-2 mx-0 mx-md-1 font-size-24">
                        <div class="col-4">
                            <a class="text-dark-gray" href="https://www.facebook.com/rozenlauks.artis" target="_blank">
                                <font-awesome-icon :icon="['fab','facebook-f']"/>
                            </a>
                        </div>
                        <div class="col-4">
                            <a class="text-dark-gray" href="https://www.linkedin.com/in/rozenlauksartis" target="_blank">
                                <font-awesome-icon :icon="['fab','linkedin-in']"/>
                            </a>
                        </div>
                        <div class="col-4">
                            <a class="text-dark-gray" href="https://www.instagram.com/artisrozenlauks/" target="_blank">
                                <font-awesome-icon :icon="['fab','instagram']"/>
                            </a>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
</template>

<script>
export default {
  name: 'InTouch',
  components: {},
};
</script>

<style lang="less">
    .square {
        &-1 {
            color: rgba(0, 0, 0, 0.2)
        }

        &-2 {
            /*color: rgba(126, 165, 179, 0.3);*/
            color: rgba(0, 150, 136, 0.31);
            font-size: 1.4rem;
        }

        &-3 {
            color: rgba(130, 146, 179, 0.3);
            font-size: 1.2rem;
        }
    }

    .dist-level {
        &-1 {
            display: inline-block;
            /*background: rgba(59, 140, 171, 0.2);*/
            background: rgba(0, 150, 136, 0.21);
            padding: 2%;
        }

        &-2 {
           /* background: rgba(35, 90, 119, 0.2);*/
            background: rgba(0, 150, 136, 0.21);
            padding: 0 2%;
        }

        &-3 {
            padding: 2px 6px;
            /*background: rgba(35, 90, 119, 0.3);*/
            background: rgba(0, 150, 136, 0.21);
            filter: saturate(2);
        }
    }

    .text{
        &-gray-blue{
            color:#4d7684;
            &:hover{
                color: #2d4d5b;
            }
        }
      &-dark-gray{
        color: #595959;
        &:hover{
          color: #000;
        }
      }
    }
    .font-size{
        &-24{
            font-size:24px;
            line-height: 24px;
        }
    }
    .block-f{
        &-1{
            /*background: rgba(35, 90, 119, 0.1);*/
            background: rgba(0, 150, 136, 0.21);
            filter: saturate(2);
        }
        &-2{
            /*background: rgba(35, 90, 119, 0.1);*/
            background: rgba(0, 150, 136, 0.21);
            filter: saturate(1);
        }
        &-3{
            /*background: rgba(118, 123, 131, 0.1);*/
            background: rgba(0, 150, 136, 0.21);
            filter: saturate(2);
        }
        &-4{
            //background: rgba(0, 0, 0, 0.1);
            background: rgba(0, 150, 136, 0.21);
            filter: saturate(2);
        }
    }
</style>
