<template>

  <div class="thanks d-flex align-items-center px-1">
    <a href="https://www.rozenlauks.lv" class="back-button py-1 px-2"> <font-awesome-icon icon="arrow-left" />&nbsp; Back</a>
    <div class="card bounce mx-auto py-4">
      <div class="card-stripe"></div>
      <div class="p-4 bank-nr">LV95HABA0551026019139</div>
      <div class="w-100 text-left px-4 pt-3">ARTIS ROZENLAUKS</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'thanks',
  head: {
    title: {
      inner: 'Artis Rozenlauks',
      separator: ' | ',
      complement: 'Thanks',
    },
    meta: [
      { name: 'robots', content: 'noindex', id: 'meta-robots-noindex' },
      { name: 'description', content: 'Artis Rozenlauks | Creative developer from Latvia ', id: 'meta-desc' },
      { name: 'author', content: 'Artis Rozenlauks', id: 'meta-author' },
      // FB
      { property: 'og:title', content: 'Artis Rozenlauks | Thanks', id: 'meta-og-title' },
      { property: 'og:description', content: 'Artis Rozenlauks | Creative developer from Latvia ', id: 'meta-og-sescription' },
      { property: 'og:image', content: 'https://www.rozenlauks.lv/static/share-img.png?s=Nhdsp3', id: 'meta-og-image' },
      // Twitter
      { name: 'twitter:title', content: 'Artis Rozenlauks | Thanks', id: 'meta-twitter-title' },
      { name: 'twitter:description', content: 'Artis Rozenlauks | Creative developer from Latvia ', id: 'meta-twitter-description' },
    ],
  },
  components: {},
};
</script>
<style>
  @import url('https://fonts.googleapis.com/css?family=Inconsolata&display=swap');
</style>
<style lang="less">
  .thanks {
    height: 100vh;
    width: 100%;

    .card{
      animation-duration: 2s;
      animation-iteration-count: 1;

      font-family: 'Inconsolata', monospace;
      /*background-color: #2c3e50;*/
      background: url("../assets/static/card-bg.png");
      background-size: cover;
      border-radius: 12px;
      color:#fff;
      width: 100%;
      height: 100%;
      max-width: 400px;
      max-height: 230px;

      -webkit-box-shadow: 0 0 33px 14px rgba(0,0,0,0.27);
      -moz-box-shadow: 0 0 33px 14px rgba(0,0,0,0.27);
      box-shadow: 0 0 33px 14px rgba(0,0,0,0.27);

      &-stripe{
        width: 100%;
        height: 40px;
        background-color: #000;
      }
      .bank-nr{
        font-size: 20px;
      }
    }
  }
  .bounce {
    animation-name: bounce;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
  }
  @keyframes bounce {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-100px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
  }
  .back-button{
    position: absolute;
    top:0;
    margin: 10px;
    background-color: #212121;
    color:#fff !important;
    border-radius: 4px;
    font-size: 12px;
    &:hover{
      background-color: #000;
      text-decoration: none;
    }
  }
</style>
