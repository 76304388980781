<template>
    <div class="full-page">
        <b-container class="h-100">
            <b-row>
                <b-col sm="12" md="6" class="p-2 education-block">
                    <div class="w-50 mx-auto text-left">
                        <font-awesome-icon icon="square-full" class="ml-auto square-1"/>
                    </div>
                    <h3 class="dist-level-1">Education</h3>
                    <p class="p-3">
                        Primary<br>
                        <i>2002 - 2011</i><br>
                        Riga English Grammar School (RAG)
                    </p>
                    |
                    <p class="p-3 mt-2">
                        Vocational<br>
                        <i>2011 - 2015</i><br>
                        PIKC Riga State Technical School (RVT)
                    </p>
                    |
                    <p class="p-3 mt-2">
                        Bachelors degree<br>
                        <i>2015 - left</i><br>
                        Riga Technical University (RTU)
                    </p>
                </b-col>
                <b-col sm="12" md="6" class="p-2">
                    <div class="w-50 mx-auto text-left" >
                        <font-awesome-icon icon="square-full" class="ml-auto square-2"/>
                    </div>
                    <h3>Work Experience</h3>
                    <b-row>
                        <div class="col-5 text-center d-flex">
                            <img class="w-50 mx-auto align-self-center grayscale-img"
                                 src="../assets/dailes-teatris.png"/>
                        </div>
                        <div class="col text-left">
                            <p class="p-3">
                                <i>2013 - 2017</i><br>
                                Dailes Teātris ( Dailes Theatre)<br>
                                <b class="dist-level-3">Public service staff</b>
                            </p>
                        </div>
                    </b-row>
                    |
                    <b-row>
                        <div class="col-5 text-center d-flex">
                            <img class="w-50 mx-auto align-self-center grayscale-img"
                                 src="../assets/delfi-logo.png"/>
                        </div>
                        <div class="col text-left">
                            <p class="p-3">
                                <i>2017 - #NOW</i><br>
                                DELFI<br>
                                <b class="dist-level-2">IT / WEB Developer [FE/BE]</b>
                            </p>
                        </div>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
  name: 'Timeline',
  components: {},
};
</script>

<style lang="less">
    .education-block{
        p {
            display: block;
            background-color: rgba(256,256,256,0.2);
        }
    }
    .square{
        &-1{
            color: rgba(0,0,0,0.2)
        }
        &-2{
            /*color: rgba(126,165,179,0.3);*/
          color: rgba(0, 150, 136, 0.21);
            font-size: 1.4rem;
        }
    }
    .dist-level{
        &-1{
            display: inline-block;
            background: rgba(59,140,171,0.2);
            padding:2%;
        }
        &-2{ background: rgba(35, 90, 119, 0.2); padding:0 2%;}
        &-3{
            padding:2px 6px;
            background: rgba(35, 90, 119, 0.3);
            filter:saturate(2);
        }
    }
</style>
