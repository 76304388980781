<template>
    <div class="full-page">
        <b-container class="h-100">
            <b-row>
                <b-col sm="12">
                    <div class="w-100 mx-auto text-left">
                        <font-awesome-icon icon="square-full" class="ml-auto square-1"/>
                    </div>
                    <h3>Projects</h3>
                </b-col>

                <b-col sm="12" md="6" class="mt-2 project-block px-2 px-md-1">
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <b-button variant="link" class="position-absolute z-index-1"
                                  v-b-modal.modal-electrocactus>
                            <font-awesome-icon icon="eye" class="text-secondary"/>
                        </b-button>
                        <b-modal id="modal-electrocactus" size="lg" centered  title="">
                            <img class="img-fluid"
                                 src="../assets/projects/preview_electrocactus.png"/>
                        </b-modal>
                        <div class="col-6 d-flex">
                            <img src="../assets/projects/electrocactus_logo.png"
                                 class="project-logo my-auto mx-auto
                                  align-self-center grayscale-img"/>
                        </div>
                        <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>Electro Cactus</b>
                                <br> Web page @ <i>2014</i>
                                <br> <i>Development</i>
                            </span>

                        </div>
                    </div>
                    |
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <div class="col-6 d-flex">
                            <img src="../assets/projects/pirmscopes_logo.png"
                                 class="project-logo my-auto mx-auto
                                  align-self-center grayscale-img"/>
                        </div>
                        <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>Pirms Copes</b>
                                <br> Web page @ <i>2015</i>
                                 <br> <i>Development & Design</i>
                            </span>
                        </div>
                    </div>
                    |
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <b-button variant="link" class="position-absolute z-index-1"
                                  v-b-modal.modal-ards>
                            <font-awesome-icon icon="eye" class="text-secondary"/>
                        </b-button>
                        <b-modal id="modal-ards" size="lg" centered  title="">
                            <img class="img-fluid"
                                 src="../assets/projects/preview_ards.png"/>
                        </b-modal>
                        <div class="col-6 d-flex">
                            <img src="../assets/projects/ards_logo.png"
                                 class="project-logo my-auto mx-auto
                                  align-self-center grayscale-img"/>
                        </div>
                        <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>ARDS</b>
                                <br> Web page @ <i>2015</i>
                                 <br> <i>Development & Design</i>
                            </span>
                        </div>
                    </div>
                    |
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <b-button variant="link" class="position-absolute z-index-1"
                                  v-b-modal.modal-partlyjobs>
                            <font-awesome-icon icon="play-circle" class="text-secondary"/>
                        </b-button>
                        <b-modal id="modal-partlyjobs" size="lg" centered  title="">
                            <img class="img-fluid"
                                 src="../assets/projects/preview_partly_jobs.gif"/>
                        </b-modal>
                        <div class="col-6 d-flex">
                            <img src="../assets/projects/partly_logo.png"
                                 class="project-logo my-auto mx-auto
                                  align-self-center grayscale-img"/>
                        </div>
                        <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>Partly - Jobs</b>
                                <br> Web page @ <i>2019</i>
                                 <br> <i>Development</i>
                            </span>
                        </div>
                    </div>
                  |
                  <div class="row p-3 item mb-2 mx-0 mx-md-1">
                    <b-button variant="link" class="position-absolute z-index-1"
                              v-b-modal.modal-vess>
                      <font-awesome-icon icon="play-circle" class="text-secondary"/>
                    </b-button>
                    <b-modal id="modal-vess" size="lg" centered  title="">
                      <img class="img-fluid"
                           src="../assets/projects/preview_vess.gif"/>
                    </b-modal>
                    <div class="col-6 d-flex">
                      <img src="../assets/projects/vess_logo.png"
                           class="project-logo my-auto mx-auto
                            align-self-center grayscale-img"/>
                    </div>
                    <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>VESS Sistēmas</b>
                                <br> Web page @ <i>2021</i>
                                 <br> <i>Development & Design</i>
                            </span>
                    </div>
                  </div>
                </b-col>
                <b-col sm="12" md="6" class="mt-2 project-block px-2 px-md-1">
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <b-button variant="link" class="position-absolute z-index-1"
                                  v-b-modal.modal-vanags>
                            <font-awesome-icon icon="play-circle" class="text-secondary"/>
                        </b-button>
                        <b-modal id="modal-vanags" size="lg" centered  title="">
                            <img class="img-fluid"
                                 src="../assets/projects/preview_vanags.gif"/>
                        </b-modal>
                        <div class="col-6 d-flex">
                            <img src="../assets/projects/vanags_logo.png"
                                 class="project-logo my-auto mx-auto
                                  align-self-center grayscale-img"/>
                        </div>
                        <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>Vanags Autoskola</b>
                                <br> Web page / Test / CRS @ <i>2016</i>
                                <br> <i>Development & Design</i>
                            </span>
                        </div>
                    </div>
                    |
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <div class="col-6 d-flex">
                            <img src="../assets/projects/eventi_logo.png"
                                 class="project-logo my-auto mx-auto
                                  align-self-center grayscale-img"/>
                        </div>
                        <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>Eventi</b>
                                <br> Web page @ <i>2017</i>
                                 <br> <i>Development & Design</i>
                            </span>
                        </div>
                    </div>
                    |
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <b-button variant="link" class="position-absolute z-index-1"
                                  v-b-modal.modal-intercorp>
                            <font-awesome-icon icon="eye" class="text-secondary"/>
                        </b-button>
                        <b-modal id="modal-intercorp" size="lg" centered  title="">
                            <img class="img-fluid"
                                 src="../assets/projects/preview_intercorp.png"/>
                        </b-modal>
                        <div class="col-6 d-flex">
                            <img src="../assets/projects/inter_corp_logo.png"
                                 class="project-logo my-auto mx-auto
                                  align-self-center grayscale-img"/>
                        </div>
                        <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>InterCorp Latvia</b>
                                <br> Web page @ <i>2018</i>
                                 <br> <i>Development & Design</i>
                            </span>
                        </div>
                    </div>
                  |
                  <div class="row p-3 item mb-2 mx-0 mx-md-1">
                    <b-button variant="link" class="position-absolute z-index-1"
                              v-b-modal.modal-hlpartn>
                      <font-awesome-icon icon="play-circle" class="text-secondary"/>
                    </b-button>
                    <b-modal id="modal-hlpartn" size="lg" centered  title="">
                      <img class="img-fluid"
                           src="../assets/projects/preview_hlpartners.gif"/>
                    </b-modal>
                    <div class="col-6 d-flex">
                      <img src="../assets/projects/hlpartn_logo.png"
                           class="project-logo my-auto mx-auto
                            align-self-center grayscale-img"/>
                    </div>
                    <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>HL Partneri</b>
                                <br> Web page @ <i>2020</i>
                                 <br> <i>Development</i>
                            </span>
                    </div>
                  </div>
                  |
                  <div class="row p-3 item mb-2 mx-0 mx-md-1" >
                    <b-button variant="link" class="position-absolute z-index-1"
                              v-b-modal.modal-artisprecsuzannu>
                      <font-awesome-icon icon="eye" class="text-secondary"/>
                    </b-button>
                    <b-modal id="modal-artisprecsuzannu" size="lg" centered  title="">
                      <img class="img-fluid"
                           src="../assets/projects/preview_artisprecsuzannu.png"/>
                    </b-modal>
                    <div class="col-6 d-flex">
                      <img src="../assets/projects/artisprecsuzannu_logo.png"
                           class="project-logo my-auto mx-auto
                            align-self-center grayscale-img"/>
                    </div>
                    <div class="col-6 d-flex mx-auto text-left">
                            <span class="my-auto align-self-center"><b>Artis Prec Suzannu</b>
                                <br> Web page @ <i>2022</i>
                                 <br> <i>Development & Design</i>
                            </span>
                    </div>
                  </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
  name: 'Projects',
  components: {},
};
</script>
<style lang="less">
    .project-block {
        p, .item {
            background-color: rgba(256, 256, 256, 0.2);

            &:hover {
                background-color: rgba(256, 256, 256, 0.4);
                cursor: pointer;
            }
        }
    }

    .project-logo {
        width: 100%;
        height: auto;
        max-width: 80px;
    }

    .square {
        &-1 {
            color: rgba(0, 0, 0, 0.2)
        }

        &-2 {
            /*color: rgba(126, 165, 179, 0.3);*/
            color: rgba(0, 150, 136, 0.21);
            font-size: 1.4rem;
        }
    }

    .dist-level {
        &-1 {
            display: inline-block;
            background: rgba(59, 140, 171, 0.2);
            padding: 2%;
        }

        &-2 {
            background: rgba(35, 90, 119, 0.2);
            padding: 0 2%;
        }

        &-3 {
            padding: 2px 6px;
            background: rgba(35, 90, 119, 0.3);
            filter: saturate(2);
        }
    }

    .modal {
        &-body {
            padding: 0;
        }

        &-footer {
            display: none;
        }

        &-header {
            border: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;
            color: #fff;

            .close {
                color: #fff;
                font-weight: 300;
                text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.98);
                opacity: 1;
                padding: 0.7rem 1.8rem;
                outline: none;

                &:hover {
                    opacity: 0.2;
                }
            }
        }

        &-content {
            background: transparent;
            border: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
        }
    }
</style>
