import Vue from 'vue';
/* Bootstrap */
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

/* Font awesome */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserSecret,
  faAngleDown,
  faArrowRight,
  faArrowLeft,
  faSquareFull,
  faExternalLinkAlt,
  faEye,
  faPlayCircle,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import VueHead from 'vue-head';
import App from './App.vue';
import router from './router';

library.add(
  faUserSecret,
  faAngleDown,
  faArrowRight,
  faArrowLeft,
  faSquareFull,
  faExternalLinkAlt,
  faEye,
  faPlayCircle,
  faEnvelope,
  faFacebookF,
  faLinkedinIn,
  faInstagram,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueHead);
Vue.use(BootstrapVue, require('moment'));


Vue.config.productionTip = false;

const vueApp = new Vue({ // eslint-disable-line no-unused-vars
  el: '#app',
  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
});
