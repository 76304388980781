<template>
    <div class="footer py-5 d-flex align-items-center">
        <b-container>
            <b-row>
                <b-col sm="12" class="py-5">
                    <span class="blue-highlight px-2">  &copy; {{year}} </span>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  name: 'InTouch',
  components: {},
};
</script>

<style lang="less" scoped>
    .footer {
        height:60vh;
        background: url("../assets/portfolio-bg-footer.png");
        background-size: cover;
       /* background-color: #1e212a;*/
    }
    .blue-highlight{
        background-color: #1f212d;
        color:#fff;
    }
</style>
