<template>
    <div class="front-page">
        <Hero/>
        <Timeline/>
        <Projects/>
        <Assets/>
        <References/>
        <InTouch/>
        <Footer/>
    </div>
</template>

<script>
import Hero from '@/components/Hero.vue';
import Timeline from '@/components/Timeline.vue';
import Projects from '@/components/Projects.vue';
import Assets from '@/components/Assets.vue';
import References from '@/components/References.vue';
import InTouch from '@/components/InTouch.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'home',
  head: {
    title: {
      inner: 'Artis Rozenlauks',
      separator: ' | ',
      complement: 'Portfolio',
    },
    meta: [
      { name: 'description', content: 'Artis Rozenlauks | Creative developer from Latvia ', id: 'meta-desc' },
      { name: 'keywords', content: 'Artis Rozenlauks, Developer, Frontend, Backend, Portfolio, Freelancer, Programmer, Programmētājs, Mājaslapas, Aplikācijas, Node, Vue, PHP, HTML, LESS, CSS, SEO', id: 'meta-keyw' },
      { name: 'author', content: 'Artis Rozenlauks', id: 'meta-author' },
      // FB
      { property: 'og:title', content: 'Artis Rozenlauks | Portfolio', id: 'meta-og-title' },
      { property: 'og:description', content: 'Artis Rozenlauks | Creative developer from Latvia ', id: 'meta-og-sescription' },
      { property: 'og:image', content: 'https://www.rozenlauks.lv/static/share-img.png?s=Nhdsp3', id: 'meta-og-image' },
      // Twitter
      { name: 'twitter:title', content: 'Artis Rozenlauks | Portfolio', id: 'meta-twitter-title' },
      { name: 'twitter:description', content: 'Artis Rozenlauks | Creative developer from Latvia ', id: 'meta-twitter-description' },
    ],
    link: [
      { rel: 'canonical', href: 'https://www.rozenlauks.lv', id: 'meta-canonical' },
    ],
  },
  components: {
    Hero,
    Timeline,
    Projects,
    Assets,
    References,
    InTouch,
    Footer,
  },
};
</script>

<style lang="less" scoped>
    .portfolio {
        &-intro {
            height: 100vh;
            background: url("../assets/portfolio-bg-person.png");
            background-size: cover;
            background-position: top;
        }
    }
</style>
