<template>
    <!-- eslint-disable max-len -->
    <div class="jumbotron jumbotron-fluid portfolio-intro full-page">
        <b-container class="h-100">
            <b-row class="d-flex h-100">
                <b-col class="d-none d-sm-block">
<!--                  <span style="font-size:30px;line-height: 30px;">Hi,</span>-->
                </b-col>
                <b-col class="d-flex text-left pl-5">
                    <div class="align-self-center text-white">
                        <h1 class="font-weight-light">Rozenlauks <br>–– Artis</h1>
                        <span class="dist-level-1">Hello,
                            I’m Artis Rozenlauks, {{ myAge() }} year's old
                            creative developer from Latvia.
                            And this is my portfolio page.<br><br>
                            <span class="dist-level-2">So..<br>
                                I have more than {{ exp() }} years of experience in
                                programming area.
                                I'm good at Software, Application and game
                                <span class="dist-level-3">development</span>.
                                As well, I do website and interface design.
                                <br>
                                <i> –– My worst feature - "I don't know how to give up"!</i>
                            </span>
                        </span>
                    </div>
                </b-col>
            </b-row>
            <span class="arrow-down">
                <font-awesome-icon icon="angle-down" />
            </span>
        </b-container>
    </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Hero',
  components: {},
  methods: {
    myAge() {
      const born = moment('1994-12-28T12:00:00');
      const endDate = moment();
      return endDate.diff(born, 'years', false);
    },
    exp() {
      const born = moment('2009-12-28T12:00:00');
      const endDate = moment();
      return endDate.diff(born, 'years', false);
    },
  },
};
</script>

<style lang="less">
    .portfolio{
        &-intro{
            background: url("../assets/portfolio-bg-person.png") top;
            background-size: cover;

            h1{
                font-size: 10vh;
                line-height: 10.1vh;
                @media  (max-width: 768px) {
                    /*background-color: rgba(26, 54, 77,0.3);*/
                    background: rgba(0,0,0,0.4);
                    font-size: 8vh;
                    line-height: 8.1vh;
                }
            }

            .dist-level{
                &-1{
                    display: inline-block;
                    /*background: rgba(59,140,171,0.2);*/
                    background: rgba(0,0,0,0.2);
                    padding:2%;
                }
                &-2{
                 /* background: rgba(35, 90, 119, 0.2);*/
                  background: rgba(0,0,0,0.3);
                  padding:0 2%;
                }
                &-3{
                    padding:2px 6px;
                    /*background: rgba(35, 90, 119, 0.3);*/
                    background-color: rgba(0, 150, 136, 0.21);
                    filter:saturate(2);
                }
            }
            .arrow-down{
                font-size: 5vh;
            }
        }
    }
</style>
