<template>
    <div>
        <b-container class="h-100">
            <b-row>
                <b-col sm="12">
                    <div class="w-100 mx-auto text-left">
                        <font-awesome-icon icon="square-full" class="ml-auto square-2"/>
                    </div>
                    <h3>Assets</h3>
                    <i>Logos & Banners</i>
                </b-col>

                <b-col sm="12" class="mt-2  px-2 px-md-1">
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <div class="col-12">
                            <!-- Swiper -->
                            <div class="swiper-container">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                        <img src="../assets/projects/wayly_logo.png"
                                             class="grayscale-img"/>
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="../assets/projects/eventi_logo.png"
                                             class="grayscale-img"/>
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="../assets/projects/ymc_logo.png"
                                             class="grayscale-img"/>
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="../assets/projects/lom_banner.png"
                                             class="grayscale-img"/>
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="../assets/projects/inter_corp_logo.png"
                                             class="grayscale-img"/>
                                    </div>
                                    <div class="swiper-slide">
                                        <img src="../assets/projects/ards_logo.png"
                                             class="grayscale-img"/>
                                    </div>
                                </div>
                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.css">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/css/swiper.min.css">

        <script type="application/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/js/swiper.js"></script>
        <script type="application/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.5.0/js/swiper.min.js"></script>

        <script type="application/javascript" >

          window.onload = function(){
            var swiper = new Swiper('.swiper-container', {
              pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
              },
              slidesPerView: 4,
              spaceBetween: 5,
              loop: true,
              autoplay: {
                delay: 5000,
                disableOnInteraction: false,
              },
              breakpoints: {
                768: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                }
              }
            });
          }
        </script>
    </div>
</template>

<script>
export default {
  name: 'Assets',
  components: {},
};
</script>

<style lang="less" >
    .square {
        &-1 {
            color: rgba(0, 0, 0, 0.2)
        }

        &-2 {
           /* color: rgba(126, 165, 179, 0.3);*/
            color: rgba(0, 0, 0, 0.7);
            font-size: 1.4rem;
        }
    }

    .dist-level {
        &-1 {
            display: inline-block;
           /* background: rgba(59, 140, 171, 0.2);*/
            background: rgba(0,0,0,0.4);
            padding: 2%;
        }

        &-2 {
            /*background: rgba(35, 90, 119, 0.2);*/
            background: rgba(0, 0, 0, 0.2);
            padding: 0 2%;
        }

        &-3 {
            padding: 2px 6px;
            //background: rgba(35, 90, 119, 0.3);
            background: rgba(0, 0, 0, 0.3);
            filter: saturate(2);
        }
    }
    .swiper{
        &-container{
            width: 100%;
            height: 100%;
        }
        &-slide{
            min-height:250px;
            text-align: center;
            font-size: 18px;
            background: transparent;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            img{
                width: 100%;
                height: auto;
                max-width: 130px;
            }
        }
        &-pagination{
            &-progressbar{
                height: 2px !important;
                &-fill{
                    background: #444 !important;
                }
            }
        }
    }
</style>
