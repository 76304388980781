<template>
    <div class="full-page">
        <b-container class="h-100">
            <b-row>
                <b-col sm="12">
                    <div class="w-100 mx-auto text-left mt-5">
                        <font-awesome-icon icon="square-full" class="ml-auto square-3"/>
                    </div>
                    <h3>References</h3>
                </b-col>

                <b-col sm="12" class="mt-2  px-2 px-md-1">
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <div class="col-12 col-md-4">
                            <img class="img-fluid pb-2 grayscale-img"
                                 src="../assets/references/references_delfi2017.gif"/>
                        </div>
                        <div class="col-12 col-md-8 text-left">
                            <h3>
                            <a class="text-dark" target="_blank" href="https://www.delfi.lv/news/delfi-par-mums/delfi-18-prieciga-ballite-holivudiskas-noskanas.d?id=49510449">
                                DELFI - Special gift 2017
                            </a>
                            </h3>
                            [LAT]
                            <div class="mt-1 pl-2 text-secondary border-secondary border-left">
                                 – latviešu portāla redaktore Guna Gleizde
                                (starp citu, otro gadu pēc kārtas) un pārdošanas
                                daļas projektu vadītāja Elīna Jurkēviča.
                                <b class="text-dark">Artim Rozenlaukam no IT daļas – valdes
                                    priekšsēdētāja speciālbalva</b>.
                            </div>
                        </div>
                    </div>
                    <div class="row p-3 item mb-2 mx-0 mx-md-1">
                        <div class="col-12 col-md-4">
                            <img class="img-fluid pb-2 grayscale-img"
                                 src="../assets/references/references_rvt2015.png"/>
                        </div>
                        <div class="col-12 col-md-8 text-left">
                            <h3>
                                <a class="text-dark" target="_blank"  href="http://www.rvt.lv/nodalas/datorikas-nodala/jaunumi-datortehnikas-nodala/1437-atorikas-nodala-notika-programmesanas-tehniku-kvalifikacijas-darbu-aizstavesana">
                                    Perfect Results in exams [RVT]
                                </a>
                            </h3>
                            [LAT]
                            <div class="mt-1 pl-2 text-secondary border-secondary border-left">
                                2015.g. 11. jūnijā Datorikas nodaļā notika "Programmēšanas tehniķu"
                                Kvalifikācijas darbu aizstāvēšana. <b class="text-dark">Izcilu
                                rezultātu parādīja</b> D41
                                grupas audzēkni: Mikus Elksnītis,
                                RihardsGričkus-Gričkis, Mārtiņš Kurmis,
                                Mihaīls Severgins un D42 grupas audzēkņi:
                                Modris Apse,  <b class="text-dark">Artis Rozenlauks</b>.
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
  name: 'References',
  components: {},
};
</script>

<style lang="less" scoped>
    .square {
        &-1 {
            color: rgba(0, 0, 0, 0.2)
        }

        &-2 {
            /*color: rgba(126, 165, 179, 0.3);*/
          color: rgba(0, 150, 136, 0.21);
            font-size: 1.4rem;
        }
        &-3 {
            //color: rgba(130, 146, 179, 0.3);
            color: rgba(0, 150, 136, 0.31);
            font-size: 1.2rem;
        }
    }

    .dist-level {
        &-1 {
            display: inline-block;
            background: rgba(59, 140, 171, 0.2);
            padding: 2%;
        }

        &-2 {
            background: rgba(35, 90, 119, 0.2);
            padding: 0 2%;
        }

        &-3 {
            padding: 2px 6px;
            background: rgba(35, 90, 119, 0.3);
            filter: saturate(2);
        }
    }
</style>
